import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import gitOpsBannerImage from "../../images/ci/new/banner.svg";
import one from "../../images/ci/icons/1.svg";
import two from "../../images/ci/icons/2.svg";
import three from "../../images/ci/icons/3.svg";
import four from "../../images/ci/icons/4.svg";
import five from "../../images/ci/icons/5.svg";
import six from "../../images/ci/icons/6.svg";
import seven from "../../images/ci/icons/7.svg";
import eight from "../../images/ci/icons/8.svg";
export const ciComponents = [
  {
    id: 1,
    title: "ノンスクリプトの「Pipeline as a Code」",
    paragraph: (
      <span>
        YAMLのシンプルで読みやすいファイルでパイプラインを宣言して、Gitリポジトリにコミットするだけ。
        <br /> <br />
        各パイプラインステップは、実行時に自動ダウンロードされるアイソレートされたDockerコンテナの中で、実行されます。
      </span>
    ),
    image: (
      <div className="bg-black max-w-[570px]">
        <StaticImage
          src="../../images/ci/Configuration.jpg"
          alt="Configuration"
          layout="constrained"
          width={570}
          objectFit="cover"
          placeholder="dominantColor"
        />
      </div>
    ),
    bgColor: "#fff",
  },
  {
    id: 2,
    title: "エクステンションの作成とシェア",
    paragraph: (
      <span>
        既存の数百のエクステンションから選択することができるなど、独自のエクステンションを作成できます。
        <br /> <br />
        Harness
        CI（旧Drone）はコンテナを使い、事前に設定したステップを標準化して、あなたのパイプラインの中にドロップします。
      </span>
    ),
    image: (
      <StaticImage
        src="../../images/ci/HarnessDrone.png"
        alt="Harness Drone"
        width={570}
        placeholder="dominantColor"
      />
    ),
    bgColor: "#fff",
  },
  {
    id: 3,
    title: "10倍以上の効率でスケール",
    paragraph: (
      <span>
        Harness
        CI（旧Drone）はGoで記述されており、必要なメモリは100MB未満です（Jenkinsでは2GB、Gitlabでは8GBが必要です）。
        <br />
        <br />
        独自のCIクラウドやファームを管理しなくても、数千ユーザー規模にも非常に簡単にスケールできます。
      </span>
    ),
    image: (
      <div className="bg-black p-6 max-w-[570px]">
        <StaticImage
          src="../../images/ci/10xMoreEfficient.png"
          width={570}
          alt="10x More Efficient"
          placeholder="dominantColor"
        />
      </div>
    ),
    bgColor: "#fff",
  },
  {
    id: 4,
    title: "ビルドとテストをアイソレート",
    paragraph: (
      <span>
        全てのビルドは隔離されたコンテナで実行されるため、各チーム間で共有サーバ上でビルドが競合することを心配する必要はありません。
        <br /> <br />
        どんどんプロジェクトを進めましょう！
      </span>
    ),
    image: (
      <StaticImage
        src="../../images/ci/IsolatedBuilds.png"
        alt="Isolated Builds"
        width={570}
        placeholder="dominantColor"
      />
    ),
    bgColor: "#fff",
  },
];

export const ciCards = [
  {
    id: 1,
    title: "テストインテリジェンス",
    paragraph:
      "品質を犠牲にすることなく、テストサイクル時間を短縮します。HarnessのMLモデルを使用すると、品質を低下させずにテストサイクルを最大98%短縮することができ、イノベーションの加速に貢献します。",
    icon: (
      <img
        quality={100}
        src={one}
        className="w-[80px] aspect-square translate-x-[-5px]"
        alt="Source Code Manager Icon"
      />
    ),
    height: "auto",
  },
  {
    id: 2,
    title: "柔軟なインフラストラクチャーサポート",
    paragraph:
      "開発者は、k8s、VM、MacStadiumなど、選択したテクノロジーに応じて、最も効率的な方法でビルドを実行するために使用するインフラストラクチャーを選択することができます。",
    icon: (
      <img
        quality={100}
        src={two}
        alt="Any Platform Icon"
        width={80}
        height={80}
        layout="fixed"
        placeholder="dominantColor"
      />
    ),
    height: "auto",
  },
  {
    id: 3,
    title: "あらゆる言語に対応",
    paragraph:
      "Harness CIは、任意の言語に対応しています。1つのCIソリューションであらゆる言語やフレームワークに対応できるため、新しい技術を採用する際にもビルドプロセスを変更する必要がなく、柔軟に対応できます。",
    icon: (
      <img
        quality={100}
        src={three}
        alt="Any Language Icon"
        width={80}
        height={80}
        layout="fixed"
        placeholder="dominantColor"
      />
    ),
    height: "auto",
  },
  {
    id: 4,
    title: "開発者フレンドリーなエクスペリエンス",
    paragraph:
      "パイプラインは、コードを管理するのと同じ方法で管理します。Gitに保存し、YAMLで記述し（オートコンプリートとスキーマ検証を備えた組み込みのYAMLエディターを使用）、プルリクエストによって変更を行います。",
    icon: (
      <img
        quality={100}
        src={four}
        alt="Easy Installation Icon"
        width={80}
        height={80}
        layout="fixed"
        placeholder="dominantColor"
      />
    ),
    height: "auto",
  },
  {
    id: 5,
    title: "柔軟なパラメトライゼーションとテンプレートライブラリー",
    paragraph:
      "実証済みのベストプラクティスを採用し、異なるチームやプロジェクト間でパイプラインを再利用することで、導入が容易になり設定に費やす時間が短縮されます。",
    icon: (
      <img
        quality={100}
        src={five}
        alt="Customizable Icon"
        width={80}
        height={80}
        layout="fixed"
        placeholder="dominantColor"
      />
    ),
    height: "auto",
  },
  {
    id: 6,
    title: "ガバナンスとセキュリティー",
    paragraph:
      "セキュリティーの問題を回避し、コードとプロセスが常にコンプライアンスを遵守していることを確認できます。Harness CIは、完全な監査機能、柔軟できめ細かいRBACを備えており、カスタムロールやOPAに支えられたポリシーを作成して、全てのパイプラインが安全かつセキュアであることを確認することができます。",
    icon: (
      <img
        quality={100}
        src={six}
        alt="Community Icon"
        width={80}
        height={80}
        layout="fixed"
        placeholder="dominantColor"
      />
    ),
    height: "auto",
  },
  {
    id: 7,
    title: "並列実行と自動障害対策による強力なパイプライン制御",
    paragraph:
      "並列または連続したステップの実行、ステップのグループ化、任意のレベルでの条件付き実行の追加、修復を自動化するための失敗戦略の定義など、あらゆる方法でパイプラインを構築することができます。",
    icon: (
      <img
        quality={100}
        src={seven}
        alt="Customizable Icon"
        width={80}
        height={80}
        layout="fixed"
        placeholder="dominantColor"
      />
    ),
    height: "auto",
  },
  {
    id: 8,
    title: "CIとCDの統合パイプライン",
    paragraph:
      "CIとCDの両方に1つのプラットフォームを使用することで、ソフトウェアデリバリープロセスを合理化し、手動ステップを減らし、複数の製品を習得しなくても迅速に前進できるよう支援します。",
    icon: (
      <img
        quality={100}
        src={eight}
        alt="Community Icon"
        width={80}
        height={80}
        layout="fixed"
        placeholder="dominantColor"
      />
    ),
    height: "auto",
  },
];

export const devOpsBanner = {
  textContents: (
    <>
      <span className="mb-[24px] block font-bold">インテグレーション</span>
      <h3 className="hn-h3 hn-blog-title leading-[32px] mb-6 text-hn-blog-title">
        DevOpsツールチェーンとのシームレスな統合
      </h3>
      <p className="hn-p1">
        ツールのインテグレーションはチームに必要不可欠です。Harnessは、お客様のスタック全体と統合し、オーケストレーションします。{" "}
      </p>
    </>
  ),
  img: (
    <img
      src={gitOpsBannerImage}
      width="562px"
      loading="lazy"
      alt="Harness GitOps Banner"
    />
  ),
};
