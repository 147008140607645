import React, { useState } from "react";
import Container from "@layout/container";
import HeroBanner from "../../components/common/heroBanner";
import CIIntroduction from "../../components/ci/Introduction/Introduction";
import CIDescription from "../../components/ci/Description/Description";
import GridCards from "../../components/common/gridCards/GridCards";
import { ciCards, devOpsBanner } from "../../components/ci/contents";
import VideoLightBox from "../../components/common/videoLightBox/VideoLightBox";
import BannerImag from "../../images/ci/ciBanner.svg";
import Meta from "../../components/seo/Meta";
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import CasualBanner from "@components/common/casualBanner/CasualBanner";
import CiDescription from "../../images/ci/new/ci_description.svg";
const CI = (props) => {
  const [isVideoPlaying, toggleVideoPlaying] = useState(false);
  const siteUrl = props?.data?.allSite?.nodes[0].siteMetadata?.siteUrl;
  const metaData = {
    title: `Harness CI｜Harness正規販売代理店 DXable`,
    description: `Harness CI（旧Drone）は、コンテナネイティブのセルフサービス型CIソリューションです。独自の拡張機能の作成、共有などカスタマイズが可能で、あらゆる言語、あらゆるプラットフォームをサポートする各種ソースコードシステムと互換性があります。`,
    keywords: `Harness,harness,Harness.io,harness.io,ハーネス,DevOps,CD,Continuous Integration,継続的インテグレーション,Harness代理店`,
    url: `${siteUrl}${props.path}`,
  };
  return (
    <>
      <Meta meta={metaData} />
      <Container>
        <HeroBanner
          sponsor={true}
          title={<BannerTitle />}
          paragraph={<BannerParagraph />}
          media={
            <MediaVideo
              isVideoPlaying={isVideoPlaying}
              toggleVideoPlaying={toggleVideoPlaying}
            />
          }
        />
        <section className="ci-description mb-[120px]">
          <div className={`ci-description__layout `}>
            <div className="ci-description__layout__image">
              <img src={CiDescription} alt="ci description" />
            </div>
            <div className="my-auto ci-description__layout__content ">
              <div className="flex items-center gap-4 mb-[30px]">
                <StaticImage
                  width={40}
                  height={40}
                  src="../../images/ci/new/drone.svg"
                  alt="ci description"
                />
                <h2 className="my-auto ci-description__layout__content__title">
                  Continuous Integrationの概要
                </h2>
              </div>
              <p className="ci-description__layout__body text-[24px] font-bold text-hn-blog-title leading-[28px]">
                Harness
                CIでビルドプロセスを合理化し、開発者はコードを書くことに集中しましょう。
              </p>
            </div>
          </div>
        </section>

        <GridCards
          title={"優れたチームに必要な機能"}
          cards={ciCards}
          bg="#fff"
        />
        <div className="hn-container mb-[120px]">
          <CasualBanner
            textContents={devOpsBanner.textContents}
            img={devOpsBanner.img}
          />
        </div>
      </Container>
    </>
  );
};

const BannerTitle = () => {
  return <span>Harness Continuous Integration</span>;
};

const BannerParagraph = () => {
  return (
    <>
      <span className="mb-[18px]">
        高品質で安全なコードのより迅速なデリバリー。
      </span>
      <span>
        インテリジェントなキャッシュとテストにより、ビルドを高速化します。スピードとガバナンス、開発者エクスペリエンスに優れ、数時間でテスト結果を得ることができます。オンプレミスまたはSaaSで、ビルドを高速化しましょう。パイプライン構築も数分で完了できます。
      </span>
    </>
  );
};

const MediaVideo = ({ isVideoPlaying, toggleVideoPlaying }) => {
  return (
    <>
      <button onClick={() => toggleVideoPlaying(!isVideoPlaying)}>
        <img
          src={BannerImag}
          quality={100}
          layout="fullWidth"
          loading="eager"
          alt="CI Page Panner"
          className="object-cover w-full "
        />
      </button>
      {/* {isVideoPlaying && (
        <VideoLightBox
          src={
            "https://harness-1.wistia.com/embed/iframe/fsc2b05uxz?&autoplay=1"
          }
          title={"Continuous Integration Demo"}
          toggleVideoPlaying={toggleVideoPlaying}
          isVideoPlaying={isVideoPlaying}
        />
      )} */}
    </>
  );
};

export const ciPageData = graphql`
  query fetchCiPageData {
    allSite {
      nodes {
        siteMetadata {
          siteUrl
          assetsBaseUrl
        }
      }
    }
  }
`;

export default CI;
